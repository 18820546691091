import React from "react"

interface ThankYouWrapperProps {
  leftContent: JSX.Element
  rightContent: JSX.Element
  content: any
}

export const ThankYouWrapper = ({
  leftContent,
  rightContent,
  content,
}: ThankYouWrapperProps): JSX.Element => {
  return (
    <>
      <div
        className={`flex-set-0-0 mt-20 ml-1/12 basis-5/6 md:mt-32 md:basis-3/6`}
      >
        {leftContent}
      </div>
      <div
        className={`flex-set-0-0 mr-1/12 mt-16 mb-32 basis-5/6 px-3 md:mb-56 md:basis-2/6`}
      >
        <div
          className={`steps vs-shadow rounded-[32px] bg-theme-white p-8 md:p-10`}
        >
          {content?.stepsTitle && (
            <h2 className={`mb-8 text-h5 md:text-h4`}>{content.stepsTitle}</h2>
          )}
          {rightContent}
        </div>
      </div>
    </>
  )
}

// Add any related components here
