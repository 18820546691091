import { useStaticQuery, graphql } from "gatsby"

const useTemplateContent = (section: string | string[]) => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          templateContent {
            templateContent {
              contactUs {
                title
                text
                meta {
                  title
                  description
                }
              }
              signUpThankYou {
                title
                text
                stepsTitle
                steps {
                  text
                }
              }
              callBackThankYou {
                title
                text
                stepsTitle
                steps {
                  text
                  addTeamMember
                }
              }
              contactUsThankYou {
                title
                text
                stepsTitle
                steps {
                  text
                }
              }
              signUpJoinUs {
                pageTitle
                title
                text
              }
              callBackJoinUs {
                pageTitle
                title
                text
              }
              joinUsAssurance {
                title
                text
                name
                role
                image {
                  id
                  sourceUrl
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        width: 1660
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
              logInMembersText
              logInMembersFeatures {
                text
              }
              logInText
              logInSuppliersText
              logInSuppliersFeatures {
                text
              }
              memberPortal {
                callbackForm
                support {
                  title
                  text
                  buttonLabel
                }
              }
              supplierPortalTitle
              supplierPortalText
              supplierPortalMeta {
                title
                description
              }
              supplierPortal {
                callbackForm
                editNewsText
                editNewsTitle
                editProfileTitle
                editProfileText
              }
              supplierPortalAdvertise {
                title
                text
                packages {
                  recommended
                  icon {
                    sourceUrl
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          width: 1660
                          placeholder: BLURRED
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                  title
                  features {
                    text
                  }
                  price
                }
                buttonLabel
              }
              newsArticleDeleteModal {
                title
                text
              }
              contactUsForm
              contactUsFormSuppliers
              callbackForm
              signupForm
              thankYouSignUpMeta {
                title
                description
              }
              thankYouCallbackMeta {
                title
                description
              }
              thankYouContactUsMeta {
                title
                description
              }
              joinUsMeta {
                title
                description
              }
              callbackMeta {
                title
                description
              }
              logInMeta {
                title
                description
              }
              memberPortalMeta {
                title
                description
              }
              supplierPortalMeta {
                title
                description
              }
              suppliersCallback {
                pageTitle
                title
                text
              }
              suppliersCallbackMeta {
                title
                description
              }
              membersCallback {
                pageTitle
                title
                text
              }
              membersCallbackMeta {
                title
                description
              }
              membersCallBackThankYou {
                title
                text
                stepsTitle
                steps {
                  text
                  addTeamMember
                }
              }
              membersCallbackThankYouMeta {
                title
                description
              }
              suppliersCallBackThankYou {
                title
                text
                stepsTitle
                steps {
                  text
                  addTeamMember
                }
              }
            }
          }
        }
      }
    `
  )

  // get the section from the templateContent object if section is a string
  if (typeof section === "string") {
    return wp.templateContent.templateContent[section] as any
  }
  let result: any[] = []
  if (Array.isArray(section)) {
    let temp = wp.templateContent.templateContent
    section.forEach(item => {
      // add item to result array
      result.push(temp[item])
    })
    return result
  }
}

export default useTemplateContent
